
@font-face {
    font-family: 'Inter';
    src: local('Inter-VariableFont_slnt,wght'), url('./Font/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: local('PlusJakartaSans-VariableFont_wght'), url('./Font/Plus_Jakarta_Sans/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins-Regular.ttf'), url('./Font/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro Text';
    src: local('FontsFree-Net-SFProText-Regular.ttf'), url('./Font/FontsFree-Net-SFProText-Regular.ttf') format('truetype');
}

/*
body {
    margin: 0;*/
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
    /*font-family: 'Inter', 'Plus Jakarta Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Inter', 'Plus Jakarta Sans';
}*/
