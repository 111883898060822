.App {
    text-align: center;
    max-width: 100vw;
    overflow: hidden;
    font-family: 'Inter';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
changes area here

body {
    font-family: 'Inter';
    font-style: normal;
    color: #3D3D3D;
}

/*section {
    margin-bottom: 100px;
}*/

nav {
    border-radius: 0px 0px 56px 56px;
    background-color: #f9f9fe !important;
    position: absolute;
    width: 100%;
    top: 0;
    z-index:100;
}

.nav-link {
    color: #3D3D3D;
}
.navbar-nav .nav-link.active {
    font-weight: 700;
    color: #0963A1;
}

.highlight {
    color: #0D6AA0;
}

h1 {
    color: #3D3D3D;
    line-height: 52.8px;
    line-height: 60.8px;
}

#section-1 h1 {
    font-weight: 700;
    padding: 0 24px 23px 0;
}

img {
    object-fit: contain;
    width: 100%;
}

.text-left{
    text-align:left !important;
}

.btn {
    border-radius: 30.9879px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#levelRow {
    column-count: 3;
}

.col-lg-3.col-md-6 {
    display: grid;
    grid-template-rows: 1fr auto;
    width: initial;
    break-inside: avoid;
}

.btn:focus-visible {
    color: #fff;
    background-color: #0D6AA0;
    border-color: #0D6AA0;
    outline: 0;
    box-shadow: none;
}

.card {
    background: #F9F9FE;
    border-radius: 6.976px;
    padding: 31px 25px 39px;
    margin-top: 22px;
    align-items: center;
    height: 95%;
    border: none;
}

.RAD {
    line-height: 32px;
    padding: 14px 24px;
    height: 60px;
    width: 195px;
}

#home #section-2 svg {
    fill: #0D6AA0;
    stroke: #0D6AA0;
}

.keyRow svg {
    fill: #011839;
    stroke: #011839;
}

.c2 {
    stroke: #011839;
}

.c3 {
    stroke: #F9F9FE;
}

#home #section-2 .card:hover, #section-3 .card:hover {
    background: #0D6AA0;
    color: #FFFFFF;
}

    #home #section-2 .card:hover svg, #section-3 .card:hover svg {
        fill: white;
        stroke: #F9F9FE;
    }

.keyRow .card:hover svg {
    fill: white;
    stroke: #0D6AA0;
}

.keyRow .card:hover .c2 {
    stroke: white;
}

#home #section-2 .card:hover .p3, #section-3 .card:hover .p3 {
    color: #FFFFFF;
}

.mb-23{
    margin-bottom:23px;
}

#section-4 .mb-23 {
    height: 107px;
    width: 107px;
    align-items: center;
    display:flex;
    margin-bottom:15px;
}

.marginLeft-32px {
    margin-left: 32px
}

.marginRight-16px {
    margin-right: 16.15px
}

.top-button {
    border: 1px solid #0D6AA0;
    filter: drop-shadow(3px 17px 33px rgba(14, 107, 161, 0.14));
    color: #0D6AA0;
    padding: 9.74px 23.85px 12.21px 24px;
    width: 96.85px;
    height: 43.95px;
}

.Vector1 {
    width: 112px;
    height: 100px;
    position: absolute;
    left: 7%;
    top: 3%;
    left: 23%;
    z-index: 0;
}

.Vector2 {
    position: absolute;
    width: 219px;
    height: 188px;
    right: 0;
    bottom: 12%;
    right: -21%;
    bottom: 4%;
    z-index: 0;
}

.btn-primary {
    background: #0D6AA0;
    border:none;
}

.top-button:hover {
    color: #0D6AA0;
    background-color: #fff0;
    border-color: #0D6AA0;
}

.top-button2 {
    box-shadow: 3px 17px 33px rgba(14, 107, 161, 0.14);
    padding: 9.74px 22.62px 12.21px 29px;
    width: 153.62px;
    height: 43.95px;
}

.btn-primary:hover {
    /*border: 1px solid #0D6AA0;*/
    background-color: #0D6AA0;
    /*border-color: #0D6AA0;*/
}

.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
    background-color: #0D6AA0;
    border-color: #0D6AA0;
    color:#fff;
}

.highlight1 {
    font-weight:800;
    color: #0D6AA0;
    font-family: 'Plus Jakarta Sans';
    font-size: 50px;
    line-height: 63px;
    white-space: nowrap;
}

.highlight2 {
    color: #0D6AA0;
    font-family: 'Inter';
    font-size: 50px;
    line-height: 52.8px;
    white-space: nowrap;
}

.highlight4 {
    color: #08669C;
    font-family: 'Inter';
    font-size: 24px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}

.p2 {
    font-size: 23px;
    margin: 0 100px;
    color: #5B5B5B;
    font-family: 'Inter';
    font-weight: 500;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.p3 {
    font-size: 14px;
    color: #5B5B5B;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 22px;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.p4 {
    font-size: 16px;
    font-size: 14px;
    color: #2E2E2E;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 24px;
    /*text-align: justify;*/
    font-feature-settings: 'pnum' on, 'lnum' on;
}

h3 {
    font-weight: 600;
}

.width-608 {
    padding: 0 70px 0 0;
}

#section-1 {
    padding-bottom: 50px;
    padding-top: 214px;
}

#home #section-2 {
    padding-top: 50px;
    margin-left: 272px;
    margin-right: 261.4px;
}

#section-3 {
    background: #FEC93B0D;
    background: #fec93b12;
    padding-top: 70px;
    padding-bottom: 70px;
}

#section-3 .p3 {
    text-align: left;
    color: #2E2E2E;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

#section-3 h3 {
    text-align: left;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 16px;
}

#section-3 .card:not(:hover) {
    background: white;
}

#section-3 .card:not(:hover) h3{
    color: #2E2E2E
}

.keyRow {
    flex-direction: row;
    /*max-width: 1232px;*/
    margin: auto;
}

.call-float {
    position: fixed;
    right: 3.47%;
    bottom: 25px;
    background: #0D6AA0;
    padding: 10px;
    box-shadow: 7px 5px 16px rgb(19 74 106 / 40%);
    border-radius: 9px;
    height: 45px;
    width: 45px;
}

.col-lg-3.col-md-6 {
    padding: 0 20px;
}

#section-4 {
    padding-top: 70px;
    padding-bottom: 114px;
}

#section-4 .card {
    background: white;
    align-items: flex-start;
    text-align:left;
    border-radius: initial;
    border-bottom: 2.5px solid #ffba01cc;
}

.br-72 {
    border-radius: 72px;
}

#get-started {
    /*padding: 70px 0;
    /background-image: conic-gradient(white 28.5%, #08669C 28.5%, #08669C 71.7%, white 71.7%);*/
    position: absolute;
    left: 0;
    right: 0;
}

#get-started h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32.4352px;
    line-height: 132%;
    color: #FFFFFF;
    padding:30px;
    text-align:left;
}

.get-started-mob {
    padding: 30px 0;
}

.get-started-mob h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22.4352px;
    line-height: 132%;
    color: #FFFFFF;
}

.footer-btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15.6703px;
    line-height: 19px;
    color: #08669C;
    background: #FFFFFF;
    box-shadow: 2.61172px 14.7998px 28.729px rgba(14, 107, 161, 0.14);
    border-radius: 26.9773px;
    padding: 10px 31px;
}

#footer {
    padding: 240px 0 0;
    background: #08669C;
    background: url('Image/bgfooter.png');
    background-size: cover;
    color: #fff;
}

.get-started-box {
    width: 794px;
    height: 141px;
}

#footer-logo {
    width: 157px;
    height: 58px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom:36px;
}

    #footer-logo img {
        width: 90%;
        width: 75%;
        height: auto;
    }

#footer-text p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 23.2809px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom:17px;
}

#footer-social-link .card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(9.22389px);
    border-radius: 15.3731px;
    padding: 14px 21.5px;
    flex-direction: row;
    width: 170.76px;
}

#footer-social-link .card div{
    padding: 1px 4.48px;
}

#footer h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 23.1879px;
    line-height: 28px;
}

#widget-2 h5, #widget-2 p{
    text-align:left;
}

#widget-2 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14.2695px;
    line-height: 17px;
    color: #FFFFFF;
}

#widget-3 h5, #widget-3 p{
    text-align:left;
}

#widget-3 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14.2695px;
    line-height: 17px;
    color: #FFFFFF;
}

#widget-4 .card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(9.18704px);
    padding: 17px 0;
    border-radius: 15.3117px;
    width: 268.87px;
    height: 50.83px;
    flex-direction: row;
    margin-top: 8.92px;
}

#widget-4 p {
    margin-bottom:initial;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14.2695px;
    line-height: 17px;

}

.mb-152px{
    margin-bottom: 152.69px;
}

#copyright div {
    border-top: 1.01063px solid rgba(255, 255, 255, 0.5);
    background: #08669C;
    padding: 30px;
}

#copyright div span{
    color: white;
    padding: 30px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12.3614px;
    line-height: 15px;
    color: #FFFFFF;
}

#about #section-1 svg {
    width: 252px;
    height: 183px;
}

.who-are-we {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #202020;
    padding-right: 163px;
    text-align: justify;
}

.line {
    height: 1px;
    width: 100%;
    background: #0D6AA0;
}

#about #section-2, #careers #section-2 {
    padding-top: 50px;
}

#about #section-2 h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 132%;
    text-align: left;
    color: #3D3D3D;
    max-width: fit-content;
    margin: initial;
    padding-right: 25px;
    white-space: nowrap;
}

#about #section-2 p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    text-align: justify;
    color: #626262;
    margin-bottom: 80.5px;
    margin-top: 31px;
}

.col-1\.5 {
    width: 13%;
    padding-left: initial;
}

.col-2\.5 {
    width: 17%;
    padding-left: initial;
}

.col-8\.5 {
    width: 74.666667%;
    padding-left: initial;
}

.col-9\.5 {
    width: 78%;
    padding-left: initial;
}

.linear-card {
    height: 7px;
    width: 50vw;
    padding: 0;
    background: #F0F0F0;
    position: absolute;
}

#section-3 .linear-card-hover:hover .linear-card {
    background: #0D6AA0;
}

#about #section-3 {
    background: #F9F9FE;
    background: #FFF;
    padding-top: 0px;
    padding-bottom: 50px;
    margin-bottom: 132px;
    margin-bottom: 90px;
}

    #about #section-3 [class*="col-"] {
        flex-direction: column;
        align-items: center;
    }

#about #section-3 svg{
    width: 104px;
    height: 92px;
}

#about #section-3 img{
    height: 92px;
}

#about #section-3 h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 132%;
    text-align: center;
    color: #3D3D3D;
    padding: 19px 0;
}

#about #section-3 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #626262;
}

.bg-color {
    background-image: linear-gradient(rgba(255, 255, 255, 0) 15%, rgb(13, 106, 160) 15%, rgb(13, 106, 160) 95%, rgba(255, 255, 255, 0) 95%);
}

#careers #section-1 svg {
    width: 225px;
    height: 226px;
}

#careers #section-2 {
    padding-bottom: 50px;
}

#careers #section-2 h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 132%;
    color: #3D3D3D;
    text-align: center;
    white-space: nowrap;
}

/*#careers #section-2 .working-environment-1 {
    margin: 25px 0px 0px auto;
}

#careers #section-2 .working-environment-2 {
    margin: 25px auto 0;
}*/

#careers #section-2 .card {
    /*background: #fff0;*/
    padding: 30px 15px;
    /*height: auto;*/
    /*width: 67%;*/
    /*margin: 25px auto 0;*/
}

#careers #section-2 .card:hover {
    background: #fff;
    box-shadow: -7.11724px 22.2414px 137.007px 38.2552px rgba(0, 0, 0, 0.05);
    border-radius: 6.22759px;
}

#careers #section-2 .svg, #support #section-3 .svg{
    margin-bottom:25px;
    height: 101px;
}

#careers #section-2 img{
    width: 99px;
}

#careers #section-2 h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #011839;
}

#careers #section-3 {
    background: #F9F9FE;
    margin-bottom: 90px;
}

#careers #section-3 h1 {
    font-size: 40px;
    line-height: 132%;
    color: #3D3D3D;
    margin-bottom: 38px;
}

    #careers #section-3 .card {
        background: #FFFFFF;
        border: 1px solid #0D6AA0;
        border-radius: 7px;
        padding-bottom: 19px;
    }

#careers .btn {
    font-size: 14px;
}

    #careers #section-3 h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: #011839;
    }

        #careers #section-3 h3.job-description {
            font-size: 16px;
            margin-left: 12px;
            margin-bottom: 9px;
        }

    #careers #section-3 p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align:left;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #5B5B5B;
    }

    #careers #section-3 a {
        width: fit-content;
    }

#support #section-2 h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 132%;
    color: #3D3D3D;
    padding-top: 42px;
    padding-bottom: 42px;
    max-width: fit-content;
    margin: 0 auto;
}

#support #section-3 {
    background: #F9F9FE;
    padding-top: 20px;
    padding-bottom: 430px;
}

#support #section-3 h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 132%;
    color: #0D6AA0;
    padding-bottom: 81px;
}

.row-width {
    display: flex;
    justify-content: space-between;
    width: 900px;
    margin: 0 auto;
}

.row-width-2 {
    display: flex;
    justify-content: space-between;
    width: 1020px;
    margin: 0 auto;
}

.col-width {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

#support #section-3 .card {
    background: #FFFFFF;
    box-shadow: -7.11724px 22.2414px 137.007px 38.2552px rgba(0, 0, 0, 0.05);
    border-radius: 6.22759px;
    padding: 30px 15px;
    margin: 25px auto 0;
    margin: 0 auto 0;
    height: 100%;
}

#support #section-3 svg{
    height: 91px;
    width: 91px;
}

#support #section-3 h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 118%;
    text-align: center;
    color: #011839;
}

#support #section-4 {
    margin-bottom: -10%;
}

#support #section-4 img {
    transform: translateY(-58%);
}

#contact #section-1 h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
}

#contact #section-1 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
}

#contact #section-2 .card {
    box-shadow: 28px 29px 125px 19px rgba(0, 0, 0, 0.04);
    border-radius: 21px;
    padding: 0;
    height: 100%;
}

#contact #section-2 .card .col-lg-6 {
        background: #FFFFFF;
        /*width: 49.95%;*/
        height: inherit;
        padding: 67px 110px;
    }

#contact #section-2 .card .col-lg-6 h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 147%;
    color: #4E4E4E;
    text-align: left;
}

#contact #section-2 .card .col-lg-6 h1 .highlight{
    font-weight: 600;
}

#contact #section-2 .card .col-lg-6 .highlight h1{
    font-weight: 700;
    color: #0D699E;
}

#contact #section-2 .contact-card {
    background: #FFFFFF;
    border: 1px solid #0D6AA0;
    border-radius: 7px;
    box-shadow: none;
    padding: 40px;
}

.chat-with-us {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8.74096px 14.9845px;
    gap: 6.24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12.4871px;
    line-height: 20px;
}

.contact-btn {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 26px;
    gap: 16px;
}

.contact-btn p {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #213D39;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: 0;
}

#blog #section-1 {
    padding-bottom: 65px;
    padding-top: 213px;
}

#blog #section-1 h1 {
    line-height: 132%;
    padding: 0;
}

#blog #section-2 {
    margin-top: 85px;
}

#blog #section-2 .card {
    background: white;
    border-radius: 10px;
    border: 1px solid rgba(198, 198, 198, 0.5);
    padding: 17px;
}

#blog #section-2 .right-col {
    padding-left: 46px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#blog #section-2 .card h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 67%;
    color: #2E2E2E;
    margin-top: 45px;
    text-align: left;
}

#blog #section-2 .card p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 175%;
    color: #7B7B7B;
    padding-top: 19px;
    text-align: left;
}

#blog .blog-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
}

ul.breadcrumb {
    padding: 16px 0 0;
    list-style: none;
}

ul.breadcrumb li {
    display: inline;
    font-size: 12px;
    line-height: 15px;
}

ul.breadcrumb li + li:before {
    padding: 8px;
    color: black;
    content: "";
}

ul.breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
}

ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
}

.upload-time {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4B4B4B;
}

#blog #section-2 .blogmini-card h3 {
    margin: initial;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2C2C2C;
}

#blog #section-2 .blogmini-card p {
    font-size: 12px;
    line-height: 21px;
    color: #6E6E6E;
}

#blog #section-2 button {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #1473E6;
    background: none;
}

.blog-bottom button:active svg {
    transform: translateX(10px);
}

#blog #section-3{
    margin-top: 60px;
    background: #fff;
}

#blog #section-3 .container{
    padding-right: 70px;
    padding-left: 70px;
}

.left-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

#blog #section-3 h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    color: #3D3D3D;
}

#blog #section-3 .highlight{
    white-space: nowrap;
}

#blog #section-3 button {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #1473E6;
    background: none;
    padding: 0;
}

#blog #section-4 {
    text-align: left;
}

#blog h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    text-transform: uppercase;
    color: #7E7F81;
    mix-blend-mode: normal;
}

#blog h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 46px;
    color: #141A28;
}

.person {
    display: flex;
    justify-content: flex-end;
    gap: 36px;
}

#blog #section-4 .person img {
    width: 63px;
    height: 63px;
    border-radius: 50%;
}

#blog #section-4 .person .active {
    border: 2px solid #53AA51;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#opportunities-1, #opportunities-2, #opportunities-3, #opportunities-4, #opportunities-5, #opportunities-6 {
    height: 70px;
    overflow: hidden;
}

.card-space {
    padding: 12px;
}

.contact-card-1 {
    border-radius: 21px 0px 0px 21px;
}

.contact-card-2 {
    border-radius: 0px 21px 21px 0px;
}


/*Media style rule*/
@media (max-width: 1440px) {
    .logo {
        height: 38px;
    }

    .space-logo {
        padding-left: 60px;
    }

    .width-608 {
        width: 608px;
        padding: 0 70px 0 0;
    }

    .p3 {
        font-size: 14px;
        line-height: 22px;
    }

    .p4 {
        font-size: 14px;
        line-height: 24px;
    }

    #home #section-2 {
        margin-left: 136px;
        margin-right: 130.7px;
    }

    #levelRow {
        padding: 0 32px;
        column-count: 2;
    }

    .keyRow .col-md-6 {
        padding: 0 16px;
    }
}

@media (max-width: 1400px) {

    #careers #section-2 .card {
        /* background: #fff0; */
        padding: 30px 15px;
        /* height: auto; */
        width: 90%;
        margin: 25px auto 0;
    }

    #support #section-3 {
        padding-bottom: 330px;
    }

    #support #section-4 img {
        transform: translateY(-40%);
        transform: translateY(-58%);
    }

    #contact #section-2 .card .col-lg-6 {
        padding: 67px 67px;
    }

    #contact #section-2 .card .col-lg-6 h1 {
        font-size: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 10px;
        padding-left: 10px;
    }

    #support #section-3 {
        padding-bottom: 380px;
    }

    #support #section-4 {
        padding-bottom: initial;
    }

    #support #section-4 img {
        transform: translateY(-72%);
    }

    #contact #section-2 .card .col-lg-6 {
        padding: 67px 24px;
        width: 49.94%;
    }

    #blog #section-3 h3 {
        font-size: 34px;
    }
}

@media (max-width: 992px) {
    .logo {
        margin: 28px 0px;
        height: 40px;
    }

    .r-7 {
        right: 0px;
    }

    .space-logo {
        padding-left: 12px;
    }

    .navbar-toggler-icon {
        width: 2.5rem;
        height: 2.5rem;
    }

    #section-1 {
        padding-top: 164px;
    }

    .Vector1 {
        width: 90px;
        height: 95px;
        left: 7%;
        top: initial;
        left: 20%;
        bottom: 82%;
    }

    .device {
        width: 90%;
    }

    .Vector2 {
        width: 24%;
        height: 46%;
        right: initial;
        left: 80%;
        top: 59%;
        bottom: initial;
    }

    h1 {
        font-size: 43px;
    }

    .highlight1 {
        font-size: 48px;
    }

    .highlight2 {
        font-size: 43px;
    }

    .width-608 {
        width: auto;
        padding: 0px 24px 0 0px;
        font-size: 18px;
    }

    .row-width-2 {
        width: auto;
    }

    .margin-sm-center {
        margin: 12px auto;
    }

    .p3 {
        text-align: justify;
    }

    #footer {
        background-size: 100% 100%;
    }

    .get-started-box {
        width: auto;
        height: auto;
    }

    .mob-tab-disable {
        display: none;
    }

    .mb-152px {
        margin-bottom: 52.69px;
    }

    #footer #widget-2, #footer #widget-3, #footer #widget-4 {
        margin-bottom: 72.69px;
    }

    #about #section-1 svg {
        width: 202px;
        height: 146px;
    }

    .who-are-we {
        font-size: 14px;
        line-height: 28px;
    }

    #about #section-2 .row {
        /*flex-direction: column;*/
    }

    #about #section-2 h1 {
        font-size: 30px;
        padding-right: 20px;
    }

    #about #section-2 p {
        font-size: 14px;
        line-height: 28px;
    }

    #about #section-3 h2 {
        font-size: 30px;
    }

    #about #section-3 p {
        font-size: 14px;
        line-height: 28px;
    }

    .align-items-start-tab {
        align-items: flex-start !important;
    }

    .d-block-tab {
        display: block !important;
    }

    #about #section-1 svg {
        width: 180px;
        height: 181px;
    }

    #careers #section-2 .card {
        padding: 15px;
        height: 90%;
        width: 100%;
    }

    #support #section-2 h1 {
        font-size: 30px;
    }

    #support #section-3 h1 {
        font-size: 30px;
        padding-bottom: 30px;
    }

    .row-width {
        width: 700px;
    }

    #support #section-3 {
        padding-bottom: 230px;
    }

    #support #section-4 {
        margin-bottom: -20%;
    }

    #support #section-4 img {
        transform: translateY(-60%);
    }

    #contact #section-2 .card .col-lg-6 {
        padding: 67px 110px;
        width: 100%;
    }

    #contact #section-2 .card .contact-card-1, #contact #section-2 .card .contact-card-2 {
        border-radius: 21px;
        height: auto;
    }

    #contact .bg-color {
        background-image: none;
    }

    #blog #section-3 h3 {
        font-size: 38px;
    }

    #blog #section-3 .blog-top {
        padding-top: 20px;
    }

    #blog #section-3 .row {
        display: flex;
        flex-direction: column-reverse;
    }
}

@media (max-width: 600px) {
    .logo {
        height: 32px;
        margin: 0px 0px;
    }

    nav {
        border-radius: 0px 0px 23px 23px;
        background-color: #f9f9fe !important;
    }

    .navbar-toggler-icon {
        height: 1rem;
        width: 1rem;
    }

    #section-1 {
        padding-top: 104px;
    }

    .Vector1 {
        left: 3%;
        top: 0;
        left: 10%;
        top: -13%;
        height: 65px;
    }

    .device {
        width: 90%;
        left: -5%;
    }

    .Vector2 {
        width: 109.5px;
        height: 94px;
        height: 45%;
        right: initial;
        left: 72.5%;
        top: 58%;
        bottom: initial;
    }

    .mob-disable {
        display: none;
    }

    .RAD {
        line-height: 20px;
        height: 48px;
        width: 178px;
        font-size: 16px;
        justify-content: space-around;
    }

    #section-1 h1 {
        text-align: left;
    }

    h1 {
        line-height: 42px;
        font-size: 29px;
    }

    .highlight1 {
        font-size: 39px;
        line-height: 50px;
    }

    .highlight2 {
        font-size: 29px;
        font-size: 26px;
        line-height: 42px;
    }

    .width-608 {
        width: auto;
        padding: 0px 7px 0px 0px;
        font-size: 16px;
    }

    .card {
        padding: 19px 12px 10px;
    }

    .text-sm-center {
        /*text-align: center !important;*/
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    /*section {
        margin-bottom: 70px;
    }*/

    .p2 {
        font-size: 17px;
        line-height: 32px;
        font-size: 14px;
        line-height: 27px;
        margin: auto;
        text-align: justify;
    }

    .p3 {
        font-size: 14px;
        line-height: 24px;
    }

    #section-2, #section-3 {
        margin-left: auto !important;
        margin-right: auto !important;
        padding-right: 12px;
        padding-left: 12px;
    }

    #section-2 {
        padding-bottom: 50px;
    }

    #about #section-2 {
        padding-top: 50px;
        padding-bottom: initial;
    }

    #section-2 h1, #careers #section-2 h1 {
        font-size: 26px;
    }

    #section-2 svg {
        display: block;
        max-height: 45px;
    }

    .keyRow {
        margin-right: -12px;
        margin-left: -12px;
    }

        .keyRow svg {
            max-width: 57px;
        }

    #section-3 h3 {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 8px;
    }

    #levelRow {
        padding: 0 10px;
        column-count: 1;
    }

    #section-4 .mb-23 {
        height: 64px;
        width: 64px;
        margin-bottom: 13px;
    }

    #section-4 svg {
        max-height: 60px;
        max-width: 60px;
    }

    .highlight4 {
        font-size: 20px;
        margin-bottom: 18px;
    }

    .br-72 {
        border-radius: 0 0 30px 30px;
    }

    .mb-23 {
        margin-bottom: 13px;
    }

    h3 {
        font-size: 21px;
    }

    .mb-152px {
        margin-bottom: initial;
    }

    #footer {
        padding: 40px 0 0;
        background: #08669C;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
    }

    #footer .row{
        flex-direction: column;
    }

    #footer #widget-1 {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #footer #widget-2, #footer #widget-3, #footer #widget-4 {
        padding: 50px 12px 0;
        margin: 0 auto;
    }

    #footer #widget-4{
        padding-bottom: 20px;
    }

    #widget-4 .card {
        padding: 17px 10px;
        width: auto;
        justify-content: space-between;
    }

    #widget-4 p {
        font-size: 12.5px;
    }

    #about #section-1 h1, #careers #section-1 h1 {
        padding: 24px;
    }

    #about #section-1 svg, #careers #section-1 svg {
        width: 125px;
        height: auto;
    }

    .who-are-we {
        text-align: justify;
        padding:initial;
    }

    #footer-text p{
        text-align:center;
    }

    #widget-2 h5, #widget-2 p{
        text-align:center;
    }

    #widget-3 h5, #widget-3 p{
        text-align:center;
    }

    #about #section-2 h1 {
        font-size: 25px;
        padding-right: 20px;
    }

    .bg-color {
        background-image: none;
    }

    #about #section-3 {
        margin-bottom: 60px;
    }

    #about #section-3 svg {
        width: 62px;
        height: 55px;
    }

    #about #section-3 p {
        padding-bottom: 20px;
    }

    #about #section-3 img {
        margin-top: 50px;
        height: 55px;
    }

    .hide-sm {
        display: none;
    }

    #careers #section-2 .card {
        padding: 15px;
        height: 90%;
        width: 80%;
    }

    #careers #section-2 .svg, #support #section-3 .svg {
        margin-bottom: 25px;
        height: auto;
    }

    #careers #section-2 svg, #careers #section-2 img ,#support #section-3 svg, #support #section-3 img {
        display: block;
        max-height: 55px;
    }

    #careers #section-2 h2, #support #section-3 h2 {
        font-size: 14px;
        line-height: 133%;
    }

    #careers #section-3 h3 {
        font-size: 16px;
    }

    #careers #section-3 h3.job-description {
        font-size: 14px;
    }

    #careers #section-3 p {
        font-size: 11px;
        line-height: 16px;
    }

    #careers dl, #careers ol, #careers ul {
        margin-top: 0;
        margin-bottom: 0rem;
    }

    #opportunities-1, #opportunities-2, #opportunities-3, #opportunities-4, #opportunities-5, #opportunities-6 {
        height: 60px;
    }

    #careers .btn {
        font-size: 11px;
        line-height: 12px;
    }

    #support #section-2 {
        padding-bottom: initial;
    }

    #support #section-2 h1 {
        font-size: 26px;
        padding-top: 28px;
        padding-bottom: 28px;
    }

    #support #section-3 .card {
        padding: 15px;
        height: 100%;
        width: 70%;
    }

    #support #section-3 h1 {
        font-size: 28px;
        padding-bottom: 41px;
    }

    .row-width {
        width: initial;
    }

    .row-width-2 {
        width: auto;
    }

    #support #section-3 {
        padding-bottom: 180px;
        padding-bottom: 150px;
    }

    #support #section-4 {
        padding-bottom: initial;
    }

    #support #section-4 img {
        transform: translateY(-80%);
        transform: translateY(-100%);
    }

    #contact #section-1 h1 {
        text-align: center;
    }

    #contact #section-2 .card .col-lg-6 {
        padding: 67px 37px;
    }

    #contact #section-2 .card .col-lg-6 h1 {
        font-size: 23px;
    }

    #contact #section-2 .card .contact-card-1, #contact #section-2 .card .contact-card-2 {
        border-radius: 21px;
        height: auto;
    }

    #contact form {
        transform: translateY(20px);
    }

    #blog #section-1 {
        padding-bottom: 25px;
        padding-top: 85px;
    }

    #blog #section-2 {
        margin-top: 50px;
    }

    #blog #section-2 .right-col {
        padding-left: 12px;
    }

    #blog #section-3 {
        margin-top: 0px;
        background: #fff;
        padding-top: 40px;
    }

    #blog #section-3 .container {
        padding-right: 12px;
        padding-left: 12px;
    }

    #blog #section-3 h3 {
        font-size: 32px;
    }

    #blog h4 {
        font-size: 27px;
    }
}

@media (max-width: 488px){

    #contact #section-2 .card .col-lg-6 {
        padding: 67px 27px;
    }

    #contact #section-2 .card .col-lg-6 h1 {
        font-size: 22px;
    }

    #contact #section-2 .contact-card {
        padding: 40px 20px;
    }
}

@media (min-width: 601px) and (max-width: 767px){
    #get-started h4 {
        font-size: 27.4352px;
    }

    .get-started-mob{
        display:none;
    }

    #about #section-3 p {
        padding-bottom: 20px;
    }

    #about #section-3 img {
        margin-top: 50px;
    }

    .hide-sm{
        display:none;
    }

    /*#widget-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }*/

    #widget-4 .card {
        width: 223px;
    }

    .bg-color {
        background-image: none;
    }

    #careers #section-2 .card {
        padding: 15px;
        height: 90%;
        width: 100%;
    }

    .row-width {
        width: 530px;
    }

    #support #section-3 {
        padding-bottom: 180px;
    }

    #contact #section-2 .card .col-lg-6 {
        padding: 67px 67px;
    }

    #contact #section-2 .card .col-lg-6 h1 {
        font-size: 25px;
    }
}

@media (min-width: 768px) {
    #home #section-2 {
        max-width: 758px;
        margin: 0 auto 62px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .p2 {
        margin: 0;
    }

    .width-608 {
        width: auto;
        padding: 0 70px 0 0;
    }

    .get-started-mob {
        display: none;
    }

    #about #section-3 .col-md-6 {
        width: 49.9%;
        padding: 0;
    }

    .vision {
        max-width: 360px;
        margin-left: auto;
    }

    .mission {
        max-width: 360px;
        margin-right: auto;
    }

    #about #section-3 p {
        padding-right: 45px;
        padding-left: 45px;
        text-align: justify;
    }

    .hide-md{
        display:none;
    }
}

@media (min-width: 992px) {

    .zoom-90{
        zoom: 90%;
    }

    .r-7{
        right:-7px;
    }

    nav {
        height: 138px;
    }

    #home #section-2 {
        max-width: 961px;
    }

    #section-2 h1 {
        max-width: 724px;
        margin: 0 auto;
    }

    #levelRow {
        padding: 0;
    }

    .col-lg-3.col-md-6 {
        padding: 0 7px;
    }

    #section-3 .p2 {
        max-width: 980px;
        max-width: 880px;
        margin: 0 auto;
    }

    .pl-2 {
        padding-left: .5rem !important;
    }

    .col-lg-13 {
        flex: 0 0 auto;
        width: 33.33%;
    }

    #section-2 h3 {
        font-size: 22px;
        line-height: 24px;
        width: 233px;
    }

    #widget-4 .card {
        width: 190px;
    }

    .vision {
        max-width: 480px;
        margin-left: auto;
    }

    .mission {
        max-width: 480px;
        margin-right: auto;
    }

    #about #section-3 p {
        padding-right: 58px;
        padding-left: 58px;
    }

    .hide-lg {
        display: none;
    }

    #contact #section-1 {
        padding-bottom: 152px;
    }

    .card-trans {
        transform: translateY(-84px);
    }

    #contact .bg-color {
        background-image: linear-gradient(#fff 25%, rgb(13, 106, 160) 25%, rgb(13, 106, 160) 80%, #fff 80%);
    }
}

@media (min-width: 1200px){

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 21px;
        padding-left: 21px;
    }

    .col-xl-1\.5 {
        width: 10%;
    }

    .col-xl-2\.5 {
        width: 20%;
    }

    #home #section-2 {
        max-width: 1160px;
    }

    #section-2 h1 {
        max-width: 893px;
    }

    #levelRow {
        padding: 0 12px;
    }

    .col-lg-13 {
        /*height: 394px;*/
        flex: 0 0 auto;
        width: 29.17%;
        width: 25%;
    }

    .col-lg-3.col-md-6 {
        padding: 0 20px;
    }

    #about #section-3 .col-md-6 {
        width: 49.95%;
    }

    .vision {
        max-width: 570px;
        margin-left: auto;
    }

    .mission {
        max-width: 570px;
        margin-right: auto;
    }

    #about #section-3 p {
        padding-right: 70px;
        padding-left: 70px;
    }

    #contact #section-2 .card .col-lg-6 {
        width: 49.95%;
    }
}

@media (min-width: 1400px) {

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 21px;
        padding-left: 21px;
    }

    #home #section-2 {
        max-width: 1320px;
    }

    #section-2 h1 {
         max-width: 743px;
         margin: 0 auto;
    }

    #section-3 h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 45px;
        line-height: 142%;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #2E2E2E;
    }

    .p2 {
        margin: 0 auto 37px;
        max-width: 848px;
        font-size: 20px;
        line-height: 30px;
    }

    .highlight2 {
        font-size: 40px;
    }

    #widget-4 .card {
        width: 223px;
    }

    .vision {
        max-width: 660px;
        margin-left: auto;
    }

    .mission {
        max-width: 660px;
        margin-right: auto;
    }

    #about #section-3 p {
        padding-right: 75px;
        padding-left: 75px;
    }

    #contact #section-1 h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 132%;
        color: #FFFFFF;
    }

    #contact #section-1 p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #FFFFFF;
    }
}

@media (min-width: 1441px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px;
    }

    .logo {
        height: 47px;
    }

    .space-logo{
        padding-left:60px;
    }

    h1 {
        font-size: 44px;
        line-height: 55.8px;
    }

    .device {
        top: 55.8px;
        left: 7%;
    }

    p {
        font-size: 19px;
        font-family: 'Inter';
        line-height: 35px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 21px;
        padding-left: 21px;
    }

    .nav-item {
        font-size: 19px;
    }

    .top-button {
        border: 1px solid #0D6AA0;
        filter: drop-shadow(3px 17px 33px rgba(14, 107, 161, 0.14));
        color: #0D6AA0;
        padding: 9.74px 28.85px 12.21px 29px;
        width: 100.85px;
        width: 104.85px;
        height: 43.95px;
        width: 107.85px;
        height: 47.95px;
    }

    .top-button2 {
        /*padding: 9.74px 34px 12.21px 34px;
        width: 159.62px;
        height: 43.95px;*/
        width: 163.62px;
        height: 47.95px;
    }

    .marginLeft-32px {
        margin-left: 32px;
    }

    .marginRight-16px {
        margin-right: 21.15px;
    }

    .highlight1 {
        font-size: 53px;
        line-height: 65px;
    }

    .highlight2 {
        font-size: 50px !important;
        line-height: 63px;
        /*line-height: 52.8px;*/
    }

    #home #section-2 {
        max-width: 1390px;
    }

    #home #section-2 h1 {
        max-width: 853px;
    }

    /*#levelRow .card{
        max-width: 307.3px;
    }

    #levelRow {
        padding-right: 18px;
        padding-left: 18px;
    }*/

    #section-4 svg {
        max-height: 70px;
    }

    .width-608 {
        padding: 0 20px 0 0;
    }

    .pl-2 {
        height: 108px;
    }

    #widget-4 .card {
        width: 268.87px;
    }
}

@media (min-width: 1741px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1620px;
    }

    .logo {
        height: 49.4px;
        height: 52px;
    }

    .space-logo {
        padding-left: 80px;
    }

    h1 {
        font-size: 40px;
        line-height: 60.8px;
        line-height: 132%;
    }

    p {
        font-size: 20px;
        font-family: 'Inter';
        line-height: 35px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 23px;
        padding-left: 23px;
    }

    .nav-item {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #3D3D3D;
    }

    .top-button {
        border: 1px solid #0D6AA0;
        filter: drop-shadow(3px 17px 33px rgba(14, 107, 161, 0.14));
        color: #0D6AA0;
        /*padding: 9.74px 28.85px 12.21px 29px;*/
        padding: initial;
        /*width: 100.85px;
        width: 104.85px;
        height: 43.95px;
        width: 107.85px;
        height: 47.95px;*/
        width: 96.85px;
        height: 43.95px;
    }

    .top-button2 {
        /*padding: 9.74px 34px 12.21px 34px;
        width: 159.62px;
        height: 43.95px;*/
        /*width: 163.62px;
        height: 47.95px;*/
        width: 151.62px;
        height: 43.95px;
        padding: initial;
    }

    .marginLeft-32px {
        margin-left: 37px;
        margin-left: 28px;
    }

    .marginRight-16px {
        margin-right: 21.15px;
        margin-right: 16.15px;
    }

    .highlight1 {
        /*font-size: 60px;
        line-height: 73px;*/
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 50px;
        line-height: 63px;
        text-align: center;
        color: #0D6AA0;
    }

    .highlight2 {
        font-size: 50px !important;
        line-height: 63px;
        /*line-height: 52.8px;*/
    }

    #home #section-2 {
        max-width: 1390px;
    }

    #home #section-2 h1 {
        max-width: 800px;
        font-family: 'Inter';
        font-style: normal;
        font-size: 40px;
        line-height: 60px;
        text-align: center;
        color: #3D3D3D;
    }

    #section-3 {
        background: #fec93b0f;
    }

    /*#levelRow .card{
        max-width: 307.3px;
    }

    #levelRow {
        padding-right: 18px;
        padding-left: 18px;
    }*/

    #section-4 svg {
        max-height: 79px;
        max-width: 79px;
    }

    .width-608 {
        padding: 0 70px 0 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #828282;
    }

    .RAD {
        display: flex;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        align-items: center;
        color: #FFFFFF;
        padding:initial;
    }

    #section-4 h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 52px;
        line-height: 63px;
        text-align: center;
        color: #3D3D3D;
    }

    .highlight4{
        font-size:24px;
    }

    #about #section-3 {
        background: #F9F9FE;
        background: #FFF;
    }

    .vision {
        max-width: 810px;
        margin-left: auto;
    }

    .mission {
        max-width: 810px;
        margin-right: auto;
    }

    #about #section-3 p {
        padding-right: 100px;
        padding-left: 100px;
    }
}

@media (max-width: 1199px) {
    #section-1 .row {
        flex-direction: column-reverse;
    }

    .who-are-we {
        text-align: justify;
        padding: initial;
        padding-right: 12px;
        padding-left: 12px;
    }

    #support .who-are-we {
        text-align: center;
    }
}

@media (min-width: 348px) and (max-width: 428px){
    h1, #careers #section-2 h1, #careers #section-3 h1{
        font-size: 28px;
    }

    .highlight1{
        font-size:36px;
        line-height:40px;
    }

    #support #section-3 h1 {
        font-size: 28px;
        padding-bottom: 40.5px;
    }

    #contact #section-2 .card .col-lg-6 {
        padding: 37px 17px;
    }

    #contact #section-2 .contact-card {
        padding: 20px 18px;
    }

    #contact #section-2 .card .col-lg-6 h1 {
        font-size: 18px;
    }
}

/* Form */

html, body {
    margin: 0px;
}

iframe {
    width: 100%;
    height: 100%;
}

#crmWebToEntityForm.zcwf_lblLeft {
    width: 100%;
    padding: 25px;
    margin: 0 auto;
    box-sizing: border-box;
}

    #crmWebToEntityForm.zcwf_lblLeft * {
        box-sizing: border-box;
    }

#crmWebToEntityForm {
    text-align: left;
}

    #crmWebToEntityForm * {
        direction: ltr;
    }

.zcwf_lblLeft .zcwf_title {
    word-wrap: break-word;
    padding: 0px 6px 10px;
    font-weight: bold;
}

.zcwf_lblLeft .zcwf_col_fld input[type=text], .zcwf_lblLeft .zcwf_col_fld textarea {
    width: 60%;
    border: 1px solid #c0c6cc !important;
    resize: vertical;
    border-radius: 2px;
    float: left;
}

.zcwf_lblLeft .zcwf_col_lab {
    width: 100%;
    word-break: break-word;
    padding: 0px 6px 0px;
    margin-right: 10px;
    margin-top: 5px;
    float: left;
    min-height: 1px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.032em;
    /* dark-green */

    color: #213D39;
}

.zcwf_lblLeft .zcwf_col_fld {
    float: left;
    width: 69%;
    padding: 0px 6px 0px;
    position: relative;
    margin-top: 5px;
}

.zcwf_lblLeft .zcwf_privacy {
    padding: 6px;
}

.zcwf_lblLeft .wfrm_fld_dpNn {
    display: none;
}

.dIB {
    display: inline-block;
}

.zcwf_lblLeft .zcwf_col_fld_slt {
    width: 60%;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    font-size: 16px;
    float: left;
    resize: vertical;
    padding: 2px 5px;
}

.zcwf_lblLeft .zcwf_row:after, .zcwf_lblLeft .zcwf_col_fld:after {
    content: '';
    display: table;
    clear: both;
}

.zcwf_lblLeft .zcwf_col_help {
    float: left;
    margin-left: 7px;
    font-size: 16px;
    max-width: 35%;
    word-break: break-word;
}

.zcwf_lblLeft .zcwf_help_icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: #fff;
    border: 1px solid #c0c6cc;
    color: #c1c1c1;
    text-align: center;
    font-size: 11px;
    line-height: 16px;
    font-weight: bold;
    border-radius: 50%;
}

.zcwf_lblLeft .zcwf_row {
    margin: 15px 0px;
}

#crmWebToEntityForm input {
    width: 397px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 32px;
    padding: 14px 26px;
    border: 1px solid #E8E9EA !important;
}

#crmWebToEntityForm textarea {
    width: 397px;
    background: #FFFFFF;
    border-radius: 32px;
    padding: 14px 26px;
    border: 1px solid #E8E9EA !important;
}

.zcwf_lblLeft .formsubmit {
    margin-right: 5px;
    cursor: pointer;
    color: var(--baseColor);
    font-size: 16px;
}

.zcwf_lblLeft .zcwf_privacy_txt {
    width: 90%;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-family: Arial;
    display: inline-block;
    vertical-align: top;
    color: var(--baseColor);
    padding-top: 2px;
    margin-left: 6px;
}

.zcwf_lblLeft .zcwf_button {
    font-size: 16px;
    color: var(--baseColor);
    border: 1px solid #c0c6cc;
    padding: 3px 9px;
    border-radius: 4px;
    cursor: pointer;
    /*max-width: 120px;*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#crmWebToEntityForm #formsubmit {
    background: linear-gradient(86.26deg, #0D6AA0 -19.38%, #1585C6 102.22%), #00B8D9;
    box-shadow: 0px 2px 8px rgba(117, 131, 142, 0.04), 0px 16px 24px rgba(52, 60, 68, 0.12);
    border-radius: 32px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.032em;
    color: #FFFFFF;
}

.zcwf_lblLeft .zcwf_tooltip_over {
    position: relative;
}

.zcwf_lblLeft .zcwf_tooltip_ctn {
    position: absolute;
    background: #dedede;
    padding: 3px 6px;
    top: 3px;
    border-radius: 4px;
    word-break: break-word;
    min-width: 100px;
    max-width: 150px;
    color: var(--baseColor);
    z-index: 100;
}

.zcwf_lblLeft .zcwf_ckbox {
    float: left;
}

.zcwf_lblLeft .zcwf_file {
    width: 55%;
    box-sizing: border-box;
    float: left;
}

.clearB:after {
    content: '';
    display: block;
    clear: both;
}

@media all and (max-width: 600px) {
    .zcwf_lblLeft.zcwf_col_lab, .zcwf_lblLeft.zcwf_col_fld {
        width: auto;
        float: none !important;
    }

    .zcwf_lblLeft .zcwf_col_help {
        width: 40%;
    }

    .zcwf_lblLeft .zcwf_col_fld, #crmWebToEntityForm input, #crmWebToEntityForm textarea {
        width: 100%;
    }

    #crmWebToEntityForm.zcwf_lblLeft{
        padding: 0;
    }
}